import * as React from "react"
import { Fragment, useState } from "react"
import { Dialog, Combobox, Transition } from "@headlessui/react"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useAtom } from "jotai"
import { searchShownAtom } from "../header"

const Search = () => {
  const [query, setQuery] = useState("")
  const fetchData = e => {
    const keyword = e.target.value
    if (keyword.length > 0) {
      fetch("https://news-strapi.dxies.com/api/graphql", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: `
        query GetRssCompany ($keyword: String!) {
          companyNewsRssUrls(
          limit: 20,
          where:{ CompanyName_contains: $keyword}
          )
          {
            id
            CompanyName
          }
        }`,
          variables: {
            keyword: keyword,
          },
        }),
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          setQuery(data.data.companyNewsRssUrls)
        })
      fetch("https://news-strapi.dxies.com/api/graphql", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: `
        query GetNotRssCompany ($keyword: String!) {
          notRssUrLs(
          limit: 20,
          where:{ CompanyName_contains: $keyword}
          )
          {
            id
            CompanyName
          }
        }`,
          variables: {
            keyword: keyword,
          },
        }),
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          setQuery(data.data.notRssUrLs)
        })
    } else setQuery("")
  }
  const [isOpen, setIsOpen] = useAtom(searchShownAtom)

  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}
      afterLeave={() => setIsOpen(false)}
    >
      <Dialog
        open={isOpen}
        onClose={setIsOpen}
        className="fixed inset-0 overflow-y-auto p-4 pt-[25vh] z-[50]"
      >
        <Transition.Child
          enter="duration-300 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-200 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500/75" />
        </Transition.Child>
        <Transition.Child
          enter="duration-300 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Combobox
            onChange={company => {
              setIsOpen(false)
              setQuery("")
              window.location.href = `/company/${company.CompanyName}`
            }}
            as="div"
            className="relative mx-auto max-w-xl divide-y overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5"
          >
            <div className="flex items-center px-4">
              <MagnifyingGlassIcon className="h6- w-6 text-gray-500" />
              <Combobox.Input
                onChange={fetchData}
                className="h-12 w-full px-2 border-0 bg-transparent text-sm text-grey-800 placeholder-gray-400 focus:outline-none"
                placeholder="Search..."
              />
            </div>
            {query.length > 0 && (
              <Combobox.Options
                static
                className="max-h-96 overflow-y-auto py-4 text-sm"
              >
                {query.map(company => (
                  <Combobox.Option key={company.CompanyName} value={company}>
                    {({ active }) => (
                      <div
                        className={`cursor-pointer px-4 py-2 ${
                          active ? "bg-sky-200" : "bg-white"
                        }`}
                      >
                        {company.CompanyName}
                      </div>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
            {query && query.length === 0 && (
              <p className="p-4 text-sm text-gray-500">No result found.</p>
            )}
          </Combobox>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}

export default Search

