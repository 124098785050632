import React from "react"
import Header from "../components/header"
const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header
  if (isRootPath) {
    header = <Header />
  } else {
    header = <Header />
  }
  return (
    <div data-is-root-path={isRootPath}>
      <Header />
      <div className="dx-body min-h-screen">
        <main>{children}</main>
      </div>
      <footer>
        <p>Digital Research Corp.</p>
      </footer>
    </div>
  )
}
export default Layout

